import {
  AdwayJob,
  AmsJob,
  BlocketJob,
  DuunitoriJob,
  FacebookAndInstagramJob,
  FinnJob,
  HigherChannelsJob,
  IndeedJob, JobIndexJob, JobnetJob, LinkedInJob, NavJob, PublishingPlatforms, tikTokJob } from './publishing-platform.interface';
import ICompany from './company.interface';
import { TranslationKeys, TranslationObject, Translation } from './translation-object.interface';
import { Branch } from './branch.interface';
import { User, UserCommunicator } from './user.interface';
import { UniversalOption } from './universal-option.interface';
import { QUIZ_MODULES } from '../resources/quiz-modules';
import { Trigger } from './trigger.interface';
import { IHiringStatus } from './hiring-status.interface';
import { IRequisition } from './requisition.model';

interface IBaseJob {
  amsJob?: AmsJob;
  amsJobTemplate?: AmsJob;
  applicationDate: string;
  applicationHiringStatusTriggers: Trigger[];
  archived: boolean;
  blocketJob?: BlocketJob;
  blocketJobTemplate?: BlocketJob;
  branch: Branch | number;
  businessQuestions: Quiz;
  company: ICompany;
  contactDetail: boolean;
  creatorEnterpriseManager: UserCommunicator;
  creatorCustomer: UserCommunicator;
  finnJob?: FinnJob;
  finnJobTemplate?: FinnJob;
  guid: string;
  hiddenFromCustomers: Partial<User>[] | number[];
  hiddenFromEnterpriseManagers: Partial<User>[] | number[];
  hiddenFromUsers?: Partial<User>[];
  id: number;
  applicationHiringStatusesWhoseTriggersAreIgnored: IHiringStatus[] | number[];
  isJobTemplateDraft: boolean;
  indeedJob: IndeedJob;
  indeedJobTemplate: IndeedJob;
  introSms: TranslationObject;
  jobCategory: UniversalOption | number;
  jobDescription: string;
  jobDescriptionTemplate: string;
  jobLocation: JobLocation | number;
  jobPlatforms: PublishingPlatforms | number[];
  jobTitle: string;
  jobWorkLocation: UniversalOption | number;
  knockoutQuestions: Quiz;
  language: TranslationKeys;
  notificationEnabled: boolean;
  allowAnonymousFlow: boolean;
  navJob: NavJob;
  navJobTemplate: Partial<NavJob>;
  orderOfQuestionsModules: QUIZ_MODULES[];
  personalityTest: Translation | boolean;
  picture: string;
  psykometrikaLanguage: string;
  publishOnHigher: boolean;
  publishedJobPlatforms: PublishingPlatforms;
  reminderSms: string;
  responsibleCustomer: Partial<User> | number;
  responsibleEnterpriseManager: Partial<User> | number;
  sendReminderSms: boolean;
  shortGuid: string;
  shortJobDescription: string;
  shortJobDescriptionTemplate: string;
  shortJobUrl: string;
  status: `${JobStatus}`;
  introMainMessage: string;
  introMessage: string;
  thankYouMainMessage: string;
  thankYouMessage: string;
  typeOfEmployment: UniversalOption | number;
  typeOfEmploymentAddition: UniversalOption | number;
  video: string;
  videoQuestions: VideoQuestion;
  boundedJobIds: number[];
  disableSurveysForEdit: boolean;
  facebookAndInstagramJob?: FacebookAndInstagramJob;
  linkedinProfile: string;
  adwayJob?: AdwayJob;
  adwayJobTemplate?: Partial<AdwayJob>;
  duunitoriJob?: DuunitoriJob;
  jobindexJob?: JobIndexJob;
  jobnetJob?: JobnetJob;
  jobnetJobTemplate?: Partial<JobnetJob>;
  activationDateTime?: string;
  tikTokJob?: tikTokJob;
  linkedInJob?: LinkedInJob;
  higherChannelsJob?: HigherChannelsJob;
}

export interface IJob extends IBaseJob {
  branch: Branch;
  hiddenFromCustomers: Partial<User>[];
  hiddenFromEnterpriseManagers: Partial<User>[];
  applicationHiringStatusesWhoseTriggersAreIgnored: IHiringStatus[];
  jobCategory: UniversalOption;
  jobLocation: JobLocation;
  jobPlatforms: PublishingPlatforms;
  jobWorkLocation: UniversalOption;
  personalityTest: Translation;
  responsibleCustomer: Partial<User>;
  responsibleEnterpriseManager: Partial<User>;
  typeOfEmployment: UniversalOption;
  typeOfEmploymentAddition: UniversalOption;
  contentButton: string;
  requisition: IRequisition;
  aiUsed: Question[];
}

export interface IJobPayload extends IBaseJob {
  branch: number;
  hiddenFromCustomers: number[];
  hiddenFromEnterpriseManagers: number[];
  hiddenFromUsers: Partial<User>[];
  applicationHiringStatusesWhoseTriggersAreIgnored: number[];
  jobCategory: number;
  jobLocation: number;
  jobPlatforms: number[];
  jobWorkLocation: number;
  personalityTest: boolean;
  responsibleCustomer: number;
  responsibleEnterpriseManager: number;
  responsiblePerson: Partial<User>
  typeOfEmployment: number;
  typeOfEmploymentAddition: number;
  linkedinProfile: string;
}

export interface Quiz {
  minPercentageForPass?: number;
  passAllCandidates?: boolean;
  questions: Question[];
  rejectionMessage?: string;
  rejectionMessageMain?: string;
}

export interface InhouseQuiz {
  passAllInhouseCandidates?: boolean;
  question: Question;
}

export interface Question {
  answers: Answer[];
  questionImportance?: number;
  text?: string;
  image?: string;
  video?: string;
  questionType?: TypeOfSkillsQuestion | string;
  questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose?: boolean;
  maxNumberOfAnswersCandidateCanChoose?: number;
  id?: number;
  hideable?: boolean;
}

export interface Answer {
  text: string;
  correct?: boolean;
  desirability?: number;
  isTagVisible?: boolean;
  tag?: string;
  followUpQuestion?: Question;
}

export interface VideoQuestion {
  questions?: string[];
  q1?: string;
  q2?: string;
  q3?: string;
  video: string;
  videoMessage: string;
  videoMainMessage: string;
}

export interface ListJob {
  applicationDate: string;
  status: string;
  applications?: ListJobApplication[];
  adwayCampaignInfo?: AdwayCampaignInfo;
  adwayCampaignInfoVisible?: boolean;
  createdAt: string;
  updatedAt: string;
  id: number;
  jobTitle: string;
  jobUrl: string;
  totalApplications?: number;
  unhandledApplications?: number;
  newApplications?: number;
  archived: boolean;
  guid: string;
  jobLocationId: number;
  jobLocationName: TranslationObject;
  responsibleCustomerName: string | null;
  responsibleCustomerId: string | null;
  responsibleEnterpriseManagerName: string | null;
  responsibleEnterpriseManagerId: string | null;
  checked?: boolean;
  category: number;
}

export interface JobLocation {
  id: number;
  name: TranslationObject;
  municipality?: TranslationObject;
  region?: TranslationObject;
}

export interface ResponsiblePerson {
  createdAt: string;
  updatedAt: string;
  name: string;
}

export interface JobStatistics {
  jobsCount: number;
  activeJobs: number;
  inactiveJobs: number;
  averageApplicationsPerJob: number;
}

export interface ListJobApplication {
  id: number;
  hiringStatus: BaseHiringStatus;
  createdAt: string;
  updatedAt: string;
}

export interface BaseHiringStatus {
  id?: number;
  name: string;
  nameModified: boolean;
}

export interface JobInfo {
  applicationDate?: string;
  branch?: number;
  contactDetail?: boolean;
  guid?: string;
  excludedJobTriggersHiringStatusIds: number[],
  isTemplate?: boolean;
  jobCategory: number;
  jobLocation?: number;
  jobTitle: string;
  jobWorkLocation?: number;
  language?: TranslationKeys;
  notificationEnabled?: boolean;
  allowAnonymousFlow?: boolean;
  responsiblePerson?: Partial<User>;
  status?: `${JobStatus}`;
  triggers: Trigger[];
  typeOfEmployment?: number;
  typeOfEmploymentAddition?: number;
  responsibleCustomer?: Partial<User>;
  responsibleEnterpriseManager?: Partial<User>;
  hiddenFromCustomers?: Partial<User>[];
  hiddenFromEnterpriseManagers?: Partial<User>[];
  hiddenFromUsers?: Partial<User>[];
  boundedJobIds: number[];
  id?: number;
  linkedinProfile?: string;
  activationDateTime?: string;
}

export interface JobDetails {
  picture?: string;
  video?: string;
  jobDescription: string;
  jobDescriptionTemplate?: string;
  shortJobDescription: string;
  shortJobDescriptionTemplate?: string;
  reminderSms: string;
  thankYouMessage: string;
  thankYouMainMessage: string;
  introMessage: string;
  introMainMessage: string;
  sendReminderSms: boolean;
}
export interface QuizModules {
  orderOfQuestionsModules: QUIZ_MODULES[];
  inhouseQuestion?: InhouseQuiz;
  knockoutQuestions?: Quiz;
  businessQuestions?: Quiz;
  personalityTest?: PersonalityTest;
  videoQuestions?: VideoQuestion;
}

export interface ListTemplate {
  id: number;
  jobTitle: string;
}

export interface PersonalityTest {
  psykometrikaLanguage: string;
}

export interface ActiveJob {
  id: number;
  type: string;
  title?: string;
  guid?: string;
}

export interface ApplicationJob {
  createdAt: string;
  company: ICompany;
  jobLocation?: JobLocation;
  responsiblePerson?: ResponsiblePerson;
  id: number;
  jobTitle: string;
  language: string;
  branch?: Branch;
  archived?: boolean;
  hiddenFromUsers: Partial<User>[];
  hiddenFromCustomers?: Partial<User>[];
  hiddenFromEnterpriseManagers?: Partial<User>[];
}

export interface JobIds {
  jobIds: string[] | null;
  universalJobId?: string | null;
}

export enum TypeOfJob {
  universalJob = 'universalJob',
  job = 'job'
}

export enum TypeOfSkillsQuestion {
  singleAnswerQuestion = 'singleAnswerQuestion',
  multipleAnswersQuestion = 'multipleAnswersQuestion',
  questionWithFollowUpQuestions = 'questionWithFollowUpQuestions',
}

export interface AdwayCampaignInfo {
  atsJobId: string;
  hasErrors: boolean;
  id: string;
  recruiterPageUrl: string;
  status: string;
  title: string;
  updatedAt?: Date;
}

export enum JobStatus {
  active = 'active',
  inactive = 'inactive',
  archive = 'archive'
}
